<template>
  <ion-page>
    <ion-content>
      <div class="ch-login">
        <div>
          <div class="ch-login__img-class">
            <img src="https://ch-desktop.perimattic.com/img/clouthub-logo.ee15f411.png" />
          </div>
          <ch-register-subheader title="Welcome"
            description="Welcome to the CloutHub community where you can socialize, connect, and collaborate on topics that matter to you most, and ultimately, change lives one “clout” at a time." />
          <cs-button v-router-link="registerLink" block>
            Create New Account
          </cs-button>
          <cs-button v-router-link="loginLink" block corners="round" size="medium" fill="outline">
            Use Existing Account
          </cs-button>
          <cs-button v-if="!loggedIn" v-router-link="exploreLink" block>
            Explore CloutHub
          </cs-button>
          <br><center>
          <a href="https://ads.clouthub.com/www/delivery/cl.php?bannerid=97&amp;zoneid=68&amp;sig=11da789600414d439692e6cd641d9273b53559648dd7b8735978a8c982133238&amp;dest=https%3A%2F%2Fsecure.advantagegold.com%2Fsurvival-dispatch-11-21-24%2F" target="_new" rel="noopener nofollow"><img src="https://ads.clouthub.com/www/images/80c5d70ab0d8e5615e5f0afff6871da5.jpg" width="485" height="209" alt="" title="" border="0"></a></center> 
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import ChRegisterSubheader from '@/components/register/Subheader.vue';
import $auth from '@/services/auth';

export default {
  components: {
    ChRegisterSubheader,
  },
  computed: {
    loginLink() {
      const target = { name: 'Login' };
      const redirect = this.$router.currentRoute.query.redirect;
      if (redirect) {
        target.query = {
          redirect,
        };
      }
      return target;
    },
    registerLink() {
      const target = { name: 'Signup' };
      const redirect = this.$router.currentRoute.query.redirect;
      if (redirect) {
        target.query = {
          redirect,
        };
      }
      return target;
    },
    exploreLink() {
      const target = { name: 'PublicHome' };
      const redirect = this.$router.currentRoute.query.redirect;
      if (redirect) {
        target.query = {
          redirect,
        };
      }
      return target;
    },
    loggedIn() {
      return $auth.isLoggedIn();
    },
  },
  mounted() {
    localStorage.skipOnboarding = true;
  },
};
</script>

<style scoped>
.ch-login {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-content: center;
  background: #f7f7f7;
}
.ch-login__img-class {
  width: 150px;
  margin: 50px auto 0px;
}
.ch-login__img-class > img {
  width: 250px;
}
a {
  color: var(--cs-primary-base);
  text-decoration: none;
}
</style>
